import React from 'react';
import Balancer from 'react-wrap-balancer'


class Chat extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: '',
        chatResponse: " ",
        isLoaded: false,
        isLoading:false,
        isError:false,
        errorMessage:' '
    };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }

    async fetchChatJSON() {
        const gainersURL = "https://appengine-spring-boot-dot-gcp-test-dev-408703.ue.r.appspot.com/chat?prompt="+this.state.value;
        const requestOptions = {
            method: 'GET',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
          };
        try{
            const response = await fetch(gainersURL, requestOptions);
            const movies = await response.json();
            console.log("json", movies);
            const result = movies.toString().split('\n').map(str => <p>{str}</p>)
            this.setState ({
                chatResponse:result,
                isLoaded: true,
                isLoading: false
            });
        }catch (error) {
            console.log(error)
            this.setState({
              isError: true,
              errorMessage: error
            })
      }
    }
  
    handleSubmit(event){
      alert('Prompt: ' + this.state.value);
      this.setState ({
        isLoaded: false,
        isLoading: true,
        isError: false
    });
      this.fetchChatJSON();
      event.preventDefault();
    }
  
    render() {
      return (
        <div>
        <center>

        <form onSubmit={this.handleSubmit}>
        <h3> Sample Prompts </h3>
        <p> What are the U.S Public Holidays?</p>
        <p> How do you make a Lemonade?</p> <br></br>
       
          <h3>
            Submit your Question Prompt: <br></br><br></br>
            <textarea cols="60" rows="5" value={this.state.value} onChange={this.handleChange} />
         <br></br>
          <input type="submit" value="Submit" />
          </h3> 
        </form>
        {this.state.isLoaded && 
            <div>
        <b>Response: <br></br>  </b> 
        <Balancer> {this.state.chatResponse} </Balancer> </div>
        }
        {this.state.isLoading && 
        <div class='is-animate'>
           <div class="l">l</div>
           <div class="l">o</div>
          <div class="l">a</div>
          <div class="l">d</div>
          <div class="l">i</div>
          <div class="l">n</div>
          <div class="l">g</div>
          </div>
        }
        {this.state.isError 
        &&
        <div> <b>Error: </b>{this.state.errorMessage}</div>
        }
        </center>
        </div>
      );
    }
  }

  export default Chat;
  