import React from 'react';
import Image from './Image';
import Chat from './Chat';
import Web from './Web';
import Contact from './Contact';


class Home extends React.Component {
    render(){
      return(
        <div className="tabs">
          <h1>Try GenAI</h1>
         <Tabs>
         <Tab label="About">
             <div>
               <p> Hi, I'm <a href="https://www.linkedin.com/in/spasupuleti/" target="_blank">Vasu Pasupuleti</a>. Welcome to Try GenAI.</p> <p> This is an experiment site that uses Google Vertex AI models 
               for generating text response, generating images, and summarizing webpages. If you have questions or want to share your thoughts please contact me. </p>
             </div>
           </Tab>
           <Tab label="Chat">
          <Chat/>
           </Tab>
           <Tab label="Image">
           <Image />
           </Tab>
           <Tab label="Web">
             <Web />
           </Tab>
           <Tab label="Contact">
            <Contact />
           </Tab>
         </Tabs>
        </div>
        
      )
    }
  }
  
  class Tabs extends React.Component{
    state ={
      activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {
  
      this.setState({ activeTab: tab });
    };
    render(){
      
      let content;
      let buttons = [];
      return (
        <div>
          {React.Children.map(this.props.children, child =>{
            buttons.push(child.props.label)
            if (child.props.label === this.state.activeTab) content = child.props.children
          })}
           
          <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
          <div className="tab-content">{content}</div>
          
        </div>
      );
    }
  }
  
  const TabButtons = ({buttons, changeTab, activeTab}) =>{
     
    return(
      <div className="tab-buttons">
      {buttons.map(button =>{
         return <button className={button === activeTab? 'active': ''} onClick={()=>changeTab(button)}>{button}</button>
      })}
      </div>
    )
  }
  
  const Tab = props =>{
    return(
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }
   
  
  export default Home;
