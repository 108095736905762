import logo from './logo.svg';
import './App.css';
import Image from './Image';
import Home from './Home';

function App() {
  return (
   <Home />
 //   <NameForm />
  );
}

export default App;
