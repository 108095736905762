import React from 'react';
import Balancer from 'react-wrap-balancer'


class Web extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: '',
        webResponse: " ",
        isLoaded: false,
        isLoading:false,
        isError:false,
        errorMessage:' '
    };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }

    async fetchWebJSON() {
        const gainersURL = "https://python-backend-dot-gcp-test-dev-408703.ue.r.appspot.com/qa?url="+this.state.value;
        
        const requestOptions = {
            method: 'GET',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
          };
        try{
            const response = await fetch(gainersURL, requestOptions);
            const movies = await response.text();
            console.log("json", movies);
            const result = movies.toString().split('\n').map(str => <p>{str}</p>)
            this.setState ({
                webResponse:result,
                isLoaded: true,
                isLoading: false
            });
        }catch (error) {
            console.log(error)
            this.setState({
              isError: true,
              errorMessage: error
            })
      }
    }
  
    handleSubmit(event){
      alert('Prompt: ' + this.state.value);
      this.setState ({
        isLoaded: false,
        isLoading: true,
        isError: false
    });
      this.fetchWebJSON();
      event.preventDefault();
    }
  
    render() {
      return (
        <div>
        <center>

        <form onSubmit={this.handleSubmit}>
        <h3> Sample Prompts for Summary</h3>
        <a href="https://www.gartner.com/en/topics/generative-ai"  target="_blank"> https://www.gartner.com/en/topics/generative-ai </a> <br></br><br></br>
        <a href="https://www.cnbc.com/2024/01/02/tesla-tsla-q4-2023-vehicle-delivery-and-production-numbers.html"  target="_blank"> https://www.cnbc.com/2024/01/02/tesla-tsla-q4-2023-vehicle-delivery-and-production-numbers.html </a> <br></br>
       
          <h3>
            Submit your Web URL Prompt: <br></br><br></br>
            <textarea cols="60" rows="5" value={this.state.value} onChange={this.handleChange} />
         <br></br>
          <input type="submit" value="Submit" />
          </h3> 
        </form>
        {this.state.isLoaded && 
            <div>
        <b>Summary: <br></br>  </b> 
        <Balancer> {this.state.webResponse} </Balancer> </div>
        }
        {this.state.isLoading && 
        <div class='is-animate'>
           <div class="l">l</div>
           <div class="l">o</div>
          <div class="l">a</div>
          <div class="l">d</div>
          <div class="l">i</div>
          <div class="l">n</div>
          <div class="l">g</div>
          </div>
        }
        {this.state.isError 
        &&
        <div> <b>Error: </b>{this.state.errorMessage}</div>
        }
        </center>
        </div>
      );
    }
  }

  export default Web;
  