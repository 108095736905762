import React from 'react';
import Balancer from 'react-wrap-balancer'


class Image extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: '',
        isImageLoaded: false,
        base64Image: ' ',
        isLoading: false,
        isError:false,
        errorMessage:' '};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }

  

      async fetchImage() {
        const gainersURL = "https://appengine-spring-boot-dot-gcp-test-dev-408703.ue.r.appspot.com/image?prompt="+this.state.value;
        const requestOptions = {
            method: 'GET',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
          };
        try {
          const response = await fetch(gainersURL, requestOptions);
          const movies = await response.json();
          console.log("json", movies);
          this.setState ({
              base64Image:"data:image/png;base64," + movies,
              isImageLoaded: true,
              isLoading: false
          });
       }catch (error) {
          console.log(error)
          this.setState({
            isError: true,
            errorMessage: error
          })
    }
  }
  
    handleSubmit(event) {
      alert('Prompt: ' + this.state.value);
      this.setState ({
        isImageLoaded: false,
        isLoading: true,
        isError: false
    });
      this.fetchImage();
      event.preventDefault();
    }
  
    render() {
      const { loading } = this.state;
      return (
        <div>
        <center>

        <form onSubmit={this.handleSubmit}>
        <h3> Sample Prompts </h3>
        <p> A beautiful cat wearing christmas hat</p>
        <p> A car at the edge of a mountain</p> <br></br>
          <h3>
            Submit Your Image Prompt: <br></br><br></br>
            <textarea cols="60" rows="5" value={this.state.value} onChange={this.handleChange} />
         <br></br>
          <input type="submit" value="Submit" />
          </h3> 
        </form>
        {this.state.isError 
        &&
        <div> <b>Error: </b>{this.state.errorMessage}</div>
        }
        {this.state.isImageLoaded &&
          <div> 
          <img width="768" height="768" src={this.state.base64Image}/>
          </div>
        } 
        {this.state.isLoading && 
        <div class='is-animate'>
           <div class="l">l</div>
           <div class="l">o</div>
          <div class="l">a</div>
          <div class="l">d</div>
          <div class="l">i</div>
          <div class="l">n</div>
          <div class="l">g</div>
          </div>
        }
        </center>
        </div>
      );
    }
  }

  export default Image;
  